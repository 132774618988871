<template>
  <!-- 修改密码 -->
  <div class="reset-pwd">
    <h3>修改密码</h3>
    <div class="info">
      <el-form ref="form" :model="form" label-width="150px" :rules="formRule">
        <el-form-item label="手机号：" prop="landArea">
          <el-input
            style="width: 280px;margin-right:10px;"
            disabled
            v-model="form.checkcode"
            placeholder="请输入手机号"
          ></el-input>
          <el-button
            class="btn"
            type="info"
            @click="getSmsCode"
            :disabled="flag1"
            >{{ flag1 ? `（${current}） s` : "发送验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item label="短信验证码：" prop="yzm">
          <el-input
            v-model="form.yzm"
            placeholder="请输入短信验证码"
            style="width: 280px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPwd">
          <el-input
            v-model="form.newPwd"
            style="width: 280px;"
            show-password
            minlength="8"
            maxlength="12"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码：" prop="newPwd2">
          <el-input
            v-model="form.newPwd2"
            style="width: 280px;"
            show-password
            minlength="8"
            maxlength="12"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="success" @click="submit">确认修改</el-button>
    </div>
  </div>
</template>
    
    <script>
import { resetPwd } from "@/api/account";
import { mapGetters } from "vuex";
import { getSmsCodeNew } from "@/api/certificate";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error("密码长度在6~32个字符之间"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else if (value.length < 6 || value.length > 32) {
        callback(new Error("密码长度在6~32个字符之间"));
      } else {
        callback();
      }
    };
    return {
      decreseInterval:null,
      current:null,
      max:60,
        flag1:false,
      form: {},
      formRule: {
        yzm: [{ required: true, message: "请获取短信验证码", trigger: "blur" }],
        newPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        newPwd2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  computed: mapGetters(["userInfo"]),
  created(){
    this.form.checkcode = this.userInfo.phone;
  },
  methods: {
    async getSmsCode() {
      // 获取手机验证码
      if (this.form.checkcode) {
        let param = {
          phone: this.form.checkcode + "",
          type: "3",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(
            `已向手机号为：${this.form.checkcode} 成功发送验证码！`
          );
          this.current = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current <= this.max && this.current > 0) {
              this.current--;
              this.flag1 = true;
            } else if (this.current <= 0) {
              this.current = 0;
              this.flag1 = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } 
    },
    submit() {
      // 修改密码
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            username: this.userInfo.username,
            newpwd: this.form.newPwd,
            renewpwd: this.form.newPwd2,
            verificationCode:this.form.yzm,
          };
          let res = await resetPwd(params);
          let { code, data, msg } = res;
          if (code == 0) {
            this.$message.success("修改成功，请重新登录！");
            this.$store.dispatch("LogOut").then(() => {
              // location.reload();
              this.$router.push({ name: "login" });
            });
          } else {
            this.$message.error(msg);
          }
        }
      });
    },
  },
};
</script>
    
    <style lang="less" scoped>
    /deep/.el-form-item__content{
        text-align: left !important;
    }
    h3{
        text-indent: 30px;
        margin-bottom: 60px;
    }
.reset-pwd {
  text-align: left;
  // margin-top: 46px;
  .title {
    font-size: 15px;
    font-weight: 600;
  }
  .info {
    width: 600px;
    margin-left: 100px;
    text-align: center;
  }
}
</style>
    